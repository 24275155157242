import React, { useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box, CircularProgress } from "@mui/material"; // Add CircularProgress for loading spinner
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { message } from "antd";
import { Card } from "primereact/card";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	GetCalculatorById,
	UpdateCalculator,
} from "../../../services/Api/ContentApi";
import { BASE_URL_IMAGE } from "../../../services/Host";

const EditCalculator = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [proposalData, setProposalData] = useState([]);
	const [images, setImages] = useState([]);
	const [imagePreviews, setImagePreviews] = useState([]);
	const [removeImageIds, setRemoveImageIds] = useState([]);
	const [loading, setLoading] = useState(false); // State for loading

	const navigateToCalculators = () => {
		navigate("/calculators");
	};

	// Get proposal By ID
	useLayoutEffect(() => {
		setLoading(false); 
		GetCalculatorById(id)
			.then((res) => {
				const data = res.data.data;
				setProposalData(data);

				// Construct the full image URL using only BASE_URL_IMAGE and file_name
				if (data.file_name) {
					const imageUrl = `${BASE_URL_IMAGE}${data.file_name}`;
					setImagePreviews([imageUrl]); // Set it as an array with one element
				} else {
					// If no file_name exists, set an empty array
					setImagePreviews([]);
				}
			})
			.catch((err) => {
				console.log(err, "error");
			})
			.finally(() => setLoading(false)); // Stop loading once data is fetched
	}, [id]);

	// Handle image change
	const handleImageChange = (e) => {
		const selectedImages = Array.from(e.target.files);

		// Update state for image previews
		const newPreviews = selectedImages.map((image) =>
			URL.createObjectURL(image)
		);
		setImagePreviews(newPreviews); // Replace old previews with new ones

		// Update state for images
		setImages(selectedImages); // Store the new selected images in the state
	};

	// Remove selected image
	const handleRemoveImage = (index) => {
		const removedImage = proposalData.file_name;
		const updatedImages = [...images];
		const updatedPreviews = [...imagePreviews];
		const updatedRemoveImageIds = [...removeImageIds];

		updatedImages.splice(index, 1);
		updatedPreviews.splice(index, 1);

		// Add the ID to removeImageIds if available
		if (removedImage && removedImage.id) {
			updatedRemoveImageIds.push(removedImage.id);
		}

		setImages(updatedImages);
		setImagePreviews(updatedPreviews);
		setRemoveImageIds(updatedRemoveImageIds);
	};

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true); // Set loading to true when form submission starts
		const formData = new FormData();
		formData.append("name", proposalData?.name ? proposalData?.name : "");

		// Append images to formData
		images.forEach((image) => {
			formData.append("images", image);
		});

		UpdateCalculator(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Calculator edited successfully!");
					navigate("/calculator");
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			})
			.finally(() => setLoading(false)); // Set loading to false after submission
	};

	const navigateToUser = () => {
		navigate("/calculator");
	};

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>
					<h3 className="page-title">Update Calculator</h3>
					<p className="page-sub-title">Update Calculator</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToUser}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Calculator</span>
					</Button>
				</div>
			</Box>

			<Card style={{ padding: "20px" }}>
				<Form>
					<Row>
						<Form.Group className="mb-3">
							<Form.Label>Calculator Name:</Form.Label>
							<Form.Control
								name="name"
								defaultValue={proposalData?.name}
								type="text"
								rows={9}
								disabled
							/>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group className="mb-3">
							<Form.Label>Images:</Form.Label>
							<Form.Control
								type="file"
								name="images"
								onChange={handleImageChange}
								multiple
							/>
							{imagePreviews.map((preview, index) => (
								<div
									key={index}
									style={{
										border: "1px solid #ccc",
										borderRadius: "5px",
										padding: "10px",
										marginBottom: "10px",
										marginTop: "20px",
									}}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<img
											src={preview}
											alt={`Preview ${index}`}
											style={{
												height: "80px",
												width: "80px",
												marginRight: "10px",
											}}
										/>
										<DeleteIcon
											fontSize="large"
											color="warning"
											style={{ marginLeft: "auto", cursor: "pointer" }}
											onClick={() => handleRemoveImage(index)}
										/>
									</div>
								</div>
							))}
						</Form.Group>
					</Row>

					<div style={{ marginTop: "50px" }}>
						<Button
							icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
							severity="info"
							type="submit"
							onClick={(e) => handleSubmit(e)}
							disabled={loading} // Disable button while loading
							style={{
								height: "45px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							{loading ? "Loading..." : "Submit"}
						</Button>
						<Button
							icon="pi pi-times"
							severity="secondary"
							onClick={navigateToCalculators}
							style={{
								marginLeft: "10px",
								marginTop: "10px",
								height: "45px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Card>
		</Box>
	);
};

export default EditCalculator;
