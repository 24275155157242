/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

//Get All Courses

export const GetAllCourses = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "course/getAllCoursesByAdmin", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

//Get a course by ID
export const GetCourseById = async (id) => {
	return await axios.get(BASE_URL + `course/findCourseById/${id}`);
};

//delete user
export const DeleteCourse = async (id) => {
	return await axios.delete(BASE_URL + `course/deleteCourse/${id}`, {
		headers: {
			"Content-Type": "multipart/form-data",
			"x-access-token": localStorage.getItem("adminToken"),
		},
	});
};

export const AddCourse = async (formData) => {
	return await axios.post(BASE_URL + "course/createCourse", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const AddSection = async (formData) => {
	return await axios.post(BASE_URL + "course/createSection", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const AddLecture = async (formData) => {
	return await axios.post(BASE_URL + "course/createLecture", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const EditCourse = async (id, formData) => {
	return await axios.put(BASE_URL + `course/updateCourse/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const EditSection = async (id, formData) => {
	return await axios.put(BASE_URL + `course/updateSection/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const EditLecture = async (id, formData) => {
	return await axios.put(BASE_URL + `course/updateLecture/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteSection = async (id) => {
	return await axios.delete(BASE_URL + `course/deleteSection/${id}`, {
		headers: {
			"Content-Type": "multipart/form-data",
			"x-access-token": localStorage.getItem("adminToken"),
		},
	});
};

export const DeleteLecture = async (id) => {
	return await axios.delete(BASE_URL + `course/deleteLecture/${id}`, {
		headers: {
			"Content-Type": "multipart/form-data",
			"x-access-token": localStorage.getItem("adminToken"),
		},
	});
};

export const GetAllUserCertificate = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "course/getUserWithCertificate", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};



//quiz apis

export const AddQuestion = async (formData) => {
	return await axios.post(BASE_URL + "course/createQuestion", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const AddOptions = async (formData) => {
	return await axios.post(BASE_URL + "course/createAnswers", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const AddCorrecrtAnswer = async (formData) => {
	return await axios.post(BASE_URL + "course/provideRightAnswer", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const GetOptonsByQuestionId = async (id) => {
	return await axios.get(BASE_URL + `course/getOptionsByQuestionId/${id}`);
};

export const GetAllQuestions= async (id) => {
	return await axios.get(BASE_URL + `course/getOptionsByQuestionId/${id}`);
};