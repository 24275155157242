/** @format */

import { Button, Spin, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAllContactUs } from "../../services/Api/Api";
import { Box } from "@mui/material";
import { InputText } from "primereact/inputtext";

const ContactUs = () => {
	const navigate = useNavigate();

	const [blogData, setBlogData] = useState([]);
	const [serviceBackupData, setServiceBackupData] = useState([]);
	const [loading, setLoading] = useState(false);

	// Fetch data
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllContactUs(localStorage.getItem("adminToken"));
			setBlogData(result.data.data);
			setServiceBackupData(result.data.data);
			console.log("product==>", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

  const onSearch = (searchField) => {
		const LIST = [...serviceBackupData];
		const searchList = LIST.filter(
			(item) =>
				item.name.toLowerCase().includes(searchField.toLowerCase()) ||
				(item.email_id && item.email_id.toLowerCase().includes(searchField.toLowerCase())) // Check email_id
		);
		setBlogData(searchList);
	};


	const columns = [
		{ title: "ID", dataIndex: "id", key: "id", width: 50 },
		{ title: "Name", dataIndex: "name", key: "name", width: 150 },
		{ title: "Email", dataIndex: "email_id", key: "email_id", width: 350 },
		{
			title: "Number",
			dataIndex: "mobile_number",
			key: "mobile_number",
			width: 250,
		},
		{ title: "Query", dataIndex: "query", key: "query", width: 450 },
	];

	return (
		<Box>
			
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginBottom="20px"
					>
						<div>
							<h3 className="page-title">Quotes List</h3>{" "}
							<p className="page-sub-title">
								View all the Users who contacted us
							</p>
						</div>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Box>
								<span className="p-input-icon-left">
									<i className="pi pi-search" />
									<InputText
										type="search"
										onChange={(e) => {
											onSearch(e.target.value);
										}}
										placeholder="Search..."
									/>
								</span>
							</Box>
						</Box>
					</Box>

					<Table
						dataSource={blogData}
						columns={columns}
						rowKey={(record) => record.id}
            loading={loading}
						pagination={{ pageSize: 50 }}
					/>
			
		</Box>
	);
};

export default ContactUs;
