/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table, message, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { GetAllAboutUs } from "../../../services/Api/ContentApi";
import { Button } from "primereact/button";

const AboutUs = () => {
	const navigate = useNavigate();
	const [roleData, setRoleData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 100,
		},
		sortField: null,
		sortOrder: null,
	});

	// Fetch the data from API
	const getData = async () => {
		try {
			setLoading(true);
			const result = await GetAllAboutUs(localStorage.getItem("adminToken"));
			setRoleData(result.data.data);
		} catch (e) {
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				message.error("You do not have access to this page.");
			} else {
				message.error("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	// Navigate to the edit page
	const navigateToEditAboutUs = (id) => {
		navigate(`/editAboutUs/${id}`);
	};

	// Define table columns
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 150,
		},
		{
			title: "Heading",
			dataIndex: "name",
			key: "name",
			width: 350,
		},
		{
			title: "Actions",
			key: "action",
			width: 350,
			render: (text, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						severity="info"
						style={{ borderRadius: "25px" }}
						onClick={() => navigateToEditAboutUs([record.id])}
					/>
				</Space>
			),
		},
	];

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>
					<h3 className="page-title">About Us</h3>
					<p className="page-sub-title">About Us</p>
				</div>
			</Box>

			<Table
				dataSource={roleData}
				columns={columns}
				rowKey={(record) => record.id}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default AboutUs;
