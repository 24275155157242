/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { message } from "antd";
import { Card } from "primereact/card";
import { GetLetsGetSatrtedById, UpdateLetsGetSatrted } from "../../../services/Api/ContentApi";
import { BASE_URL_IMAGE } from "../../../services/Host";


const EditLetsGetStared = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [proposalData, setProposalData] = useState([]);
	const [images, setImages] = useState([]);
	const [imagePreviews, setImagePreviews] = useState([]);
	const [removeImageIds, setRemoveImageIds] = useState([]);
	const [loading, setLoading] = useState(false);

	const navigateToCalculators = () => {
		navigate("/letsGetStarted");
	};

	//get proposal By ID
	useLayoutEffect(() => {
		setLoading(false);
		GetLetsGetSatrtedById(id)
			.then((res) => {
				const data = res.data.data;
				setProposalData(data);

				// Construct the full image URL using only BASE_URL_IMAGE and file_name
				if (data.file_name) {
					const imageUrl = `${BASE_URL_IMAGE}${data.file_name}`;
					setImagePreviews([imageUrl]); // Set it as an array with one element
				} else {
					// If no file_name exists, set an empty array
					setImagePreviews([]);
				}
			})
			.catch((err) => {
				console.log(err, "error");
			})
			.finally(() => setLoading(false)); // Stop loading once data is fetched
	}, [id]);

	const handleImageChange = (e) => {
		const selectedImages = Array.from(e.target.files);

		// Update state for image previews
		const newPreviews = selectedImages.map((image) =>
			URL.createObjectURL(image)
		);
		setImagePreviews(newPreviews); // Replace old previews with new ones

		// Update state for images
		setImages(selectedImages); // Store the new selected images in the state
	};

	// Remove selected image
	const handleRemoveImage = (index) => {
		const removedImage = proposalData.file_name;
		const updatedImages = [...images];
		const updatedPreviews = [...imagePreviews];
		const updatedRemoveImageIds = [...removeImageIds];

		updatedImages.splice(index, 1);
		updatedPreviews.splice(index, 1);

		// Add the ID to removeImageIds if available
		if (removedImage && removedImage.id) {
			updatedRemoveImageIds.push(removedImage.id);
		}

		setImages(updatedImages);
		setImagePreviews(updatedPreviews);
		setRemoveImageIds(updatedRemoveImageIds);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		formData.append("name", proposalData?.name ? proposalData?.name : "");

		images.forEach((image) => {
			formData.append("images", image);
		});

		UpdateLetsGetSatrted(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Content edited successfully!");
				}
				navigate("/letsGetStarted");
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">

				<div>
					<h3 className="page-title">UPDATE LETS GET STARTED IMAGE</h3>
					<p className="page-sub-title">Edit Image</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToCalculators}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to List</span>
					</Button>
				</div>
			</Box>

			<Card style={{ padding: "20px" }}>
				<div>
					<Form className="admin_details_form">
						<Row>
							<Form.Group className="mb-3">
								<Form.Label>Images:</Form.Label>
								<Form.Control
									type="file"
									name="images"
									onChange={handleImageChange}
									multiple
								/>
								{imagePreviews.map((preview, index) => (
									<div
										key={index}
										style={{
											border: "1px solid #ccc",
											borderRadius: "5px",
											padding: "10px",
											marginBottom: "10px",
											marginTop: "20px",
										}}
									>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img
												src={preview}
												alt={`Preview ${index}`}
												style={{
													height: "80px",
													width: "80px",
													marginRight: "10px",
												}}
											/>
											<DeleteIcon
												fontSize="large"
												color="warning"
												style={{ marginLeft: "auto", cursor: "pointer" }}
												onClick={() => handleRemoveImage(index)}
											/>
										</div>
									</div>
								))}
							</Form.Group>
						</Row>

						<div style={{ marginTop: "50px" }}>
							<Button
								icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
								severity="info"
								type="submit"
								onClick={(e) => handleSubmit(e)}
								disabled={loading} // Disable button while loading
								style={{
									height: "45px",
									padding: "20px",
									borderRadius: "5px",
								}}
							>
								{loading ? "Loading..." : "Submit"}
							</Button>
							<Button
								icon="pi pi-times"
								severity="secondary"
								onClick={navigateToCalculators}
								style={{
									marginLeft: "10px",
									marginTop: "10px",
									height: "45px",
									padding: "20px",
									borderRadius: "5px",
								}}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</div>
			</Card>
		</Box>
	);
};

export default EditLetsGetStared;
