/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { message } from "antd";
import { Card } from "primereact/card";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	GetAboutUsById,
	UpdateAboutUs,
} from "../../../services/Api/ContentApi";
import { BASE_URL_IMAGE } from "../../../services/Host";

const EditAboutUs = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [proposalData, setProposalData] = useState([]);
	const [images, setImages] = useState([]);
	const [imagePreviews, setImagePreviews] = useState([]);
	const [removeImageIds, setRemoveImageIds] = useState([]);

	const navigateToCalculators = () => {
		navigate("/aboutUs");
	};

	//get proposal By ID
	useLayoutEffect(() => {
		GetAboutUsById(id)
			.then((res) => {
				const data = res.data.data;
				setProposalData(data);
	
				// Construct the full image URL using only BASE_URL_IMAGE and file_name
				if (data.file_name) {
					const imageUrl = `${BASE_URL_IMAGE}${data.file_name}`;
					setImagePreviews([imageUrl]); // Set it as an array with one element
				} else {
					// If no file_name exists, set an empty array
					setImagePreviews([]);
				}
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);
	
	

	const handleImageChange = (e) => {
		const selectedImages = Array.from(e.target.files);

		// Update state for image previews
		const newPreviews = selectedImages.map((image) =>
			URL.createObjectURL(image)
		);
		setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

		// Update state for images
		setImages(selectedImages);
	};
	
	const handleRemoveImage = (index) => {
		const removedImage = proposalData.file_name;
		const updatedImages = [...images];
		const updatedPreviews = [...imagePreviews];
		const updatedRemoveImageIds = [...removeImageIds]; // Assuming you have a state for removeImageIds

		updatedImages.splice(index, 1);
		updatedPreviews.splice(index, 1);

		// Add the ID to removeImageIds if available
		if (removedImage && removedImage.id) {
			updatedRemoveImageIds.push(removedImage.id);
		}

		setImages(updatedImages);
		setImagePreviews(updatedPreviews);
		setRemoveImageIds(updatedRemoveImageIds); // Set the updated removeImageIds in your state
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("name", proposalData?.name ? proposalData?.name : "");
	
		// Append all selected images to formData
		images.forEach((image) => {
			formData.append("images", image); // Multiple images (for single, use only one append)
		});
	
		UpdateAboutUs(id, formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("About Us updated successfully!");
					navigate("/aboutUs");
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};
	

	const navigateToUser = () => {
		navigate("/aboutUs");
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">Update About Us</h3>
					<p className="page-sub-title">Update About Us</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToUser}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to About Us</span>
					</Button>
				</div>
			</Box>

			<Card style={{ padding: "20px" }}>
				<div>
					<Form>
						<Row>
							{/* <Form.Group className="mb-3">
								<Form.Label>Image:</Form.Label>

								<Form.Control
									type="file"
									name="image"
									onChange={(e) => handleImageChange(e)}
								/>
								{proposalData ? (
									<>
										{image.preview === "" ? (
											<img
												src={`${BASE_URL_IMAGE}${proposalData.file_name}`}
												alt="Proposal Banner "
												style={{
													height: "100px",
													width: "100px",
													marginTop: "20px",
													marginLeft: "20px",
												}}
											/>
										) : (
											<img
												src={image.preview}
												alt="Preview"
												style={{
													height: "100px",
													width: "100px",
													marginTop: "20px",
													marginLeft: "20px",
												}}
											/>
										)}
									</>
								) : (
									<span>No Image Available</span>
								)}
							</Form.Group> */}

							<Form.Group className="mb-3">
								<Form.Label>Images:</Form.Label>
								<Form.Control
									type="file"
									name="images"
									onChange={handleImageChange}
									multiple
								/>
								{imagePreviews.map((preview, index) => (
									<div
										key={index}
										style={{
											border: "1px solid #ccc",
											borderRadius: "5px",
											padding: "10px",
											marginBottom: "10px",
											marginTop: "20px",
										}}
									>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img
												src={preview}
												alt={`Preview ${index}`}
												style={{
													height: "80px",
													width: "80px",
													marginRight: "10px",
												}}
											/>
											<DeleteIcon
												fontSize="large"
												color="warning"
												style={{ marginLeft: "auto", cursor: "pointer" }}
												onClick={() => handleRemoveImage(index)}
											/>
										</div>
									</div>
								))}
							</Form.Group>
						</Row>

						<div style={{ marginTop: "50px" }}>
							<Button
								icon="pi pi-check"
								severity="info"
								type="submit"
								onClick={(e) => {
									handleSubmit(e);
								}}
								style={{
									height: "45px",
									padding: "20px",
									borderRadius: "5px",
								}}
							>
								Submit
							</Button>
							<Button
								icon="pi pi-times"
								severity="secondary"
								onClick={() => navigateToCalculators()}
								style={{
									marginLeft: "10px",
									marginTop: "10px",
									height: "45px",
									padding: "20px",
									borderRadius: "5px",
								}}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</div>
			</Card>
		</Box>
	);
};

export default EditAboutUs;
