/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useLayoutEffect } from "react";
import { Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { GetAdminProfile } from "../../services/Api/Api.jsx";

import { Button } from "primereact/button";
import { Card } from "primereact/card";

const ViewAdmin = () => {
	const { id } = useParams();
	const [idData, setIdData] = React.useState({});

	//get admin profile
	const getData = async () => {
		try {
			let result = await GetAdminProfile(localStorage.getItem("adminToken"));
			setIdData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		getData();
	}, []);

	const navigate = useNavigate();
	const navigateToAdmin = () => {
		navigate("/");
	};
	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3>Admin Profile</h3>
					<p className="page-sub-title">View admin details</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={(e) => {
							navigateToAdmin();
						}}
						style={{ borderRadius: "5px", height: "40px" }}
					>
						<span style={{ marginLeft: "5px" }}> Return to Dashboard</span>
					</Button>
				</div>
			</Box>
			<Card style={{ padding: "20px" }}>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							defaultValue={idData?.name}
							name="name"
							disabled
						/>
					</Form.Group>

					<Form.Group className="mb-3">
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="text"
							defaultValue={idData?.email}
							name="abbreviation"
							disabled
						/>
					</Form.Group>

					{/* <Form.Group className="mb-3">
						<Form.Label>Role</Form.Label>
						<Form.Control
							type="text"
							defaultValue={idData?.admin_roles[0]?.name || ""}
							name="abbreviation"
							disabled
						/>
					</Form.Group> */}

					{/* <Form.Group className="mb-3">
              <Form.Label>Department</Form.Label>
              <Form.Control
                type="text"
                defaultValue={idData?.admin_department?.name || ""}
                name="abbreviation"
                disabled
              />
            </Form.Group> */}
				</Form>
			</Card>
		</Box>
	);
};

export default ViewAdmin;
