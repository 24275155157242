/** @format */

import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import "./Course.scss";
import { useNavigate } from "react-router-dom";

const LectureForm = ({ courseId, sectionId, handleLectureSubmit, onCancel }) => {
    const navigate = useNavigate();
    const [lectureType, setLectureType] = useState("Video");
    const [lectureName, setLectureName] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [textContent, setTextContent] = useState("");
    const [document, setDocument] = useState(null);
    const [documentNames, setDocumentNames] = useState([]);

    const fileInputRef = useRef(null);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setTextContent(data);
    };

    const handleDocumentChange = (e) => {
        const selectedDocuments = e.target.files;
        setDocument(selectedDocuments);
        setDocumentNames(Array.from(selectedDocuments).map((file) => file.name));
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleRemoveDocument = (index) => {
        const updatedDocuments = Array.from(document);
        updatedDocuments.splice(index, 1);
        setDocument(updatedDocuments);
        const updatedNames = [...documentNames];
        updatedNames.splice(index, 1);
        setDocumentNames(updatedNames);
    };

    const handleSave = () => {
        const formData = {
            courseId,
            sectionId,
            lectureName,
            duration: `${hours} Hours ${minutes} Minutes`,
            lectureType,
            videoLink,
            textContent,
            document,
        };
        handleLectureSubmit(formData);
    };

    const navigateToQuiz = () => {
        if (sectionId && courseId) {
            navigate("/quiz", {
                state: {
                    section_id: sectionId,
                    course_id: courseId
                }
            });
        } else {
            console.error("Missing sectionId or courseId.");
        }
    };

    return (
        <div>
            <div className="button-group">
                <Button
                    className="group_button"
                    variant="outlined"
                    onClick={() => setLectureType("Video")}
                    sx={{
                        background: lectureType === "Video" ? "black" : "white",
                        color: lectureType === "Video" ? "white" : "black",
                    }}
                >
                    Video
                </Button>
                <Button
                    className="group_button"
                    variant="outlined"
                    onClick={() => setLectureType("Text")}
                    sx={{
                        background: lectureType === "Text" ? "black" : "white",
                        color: lectureType === "Text" ? "white" : "black",
                    }}
                >
                    Text
                </Button>
                <Button
                    onClick={navigateToQuiz}
                    className="group_button"
                    variant="outlined"
                    sx={{
                        background: lectureType === "Test" ? "black" : "white",
                        color: lectureType === "Test" ? "white" : "black",
                    }}
                >
                    Test (Quiz)
                </Button>
            </div>
            <div className="lecture-form-container">
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Lecture Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Please enter your Lecture Name"
                                    value={lectureName}
                                    onChange={(e) => setLectureName(e.target.value)}
                                    className="new_form_control"
                                />
                            </Form.Group>
                        </Col>
                        {lectureType === "Video" && (
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Video Link:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Please enter Video Link"
                                        value={videoLink}
                                        onChange={(e) => setVideoLink(e.target.value)}
                                        className="new_form_control"
                                    />
                                </Form.Group>
                            </Col>
                        )}
                        {lectureType === "Text" && (
                            <Form.Group className="mb-3">
                                <Form.Label>Text Content:</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    onChange={handleEditorChange}
                                    config={{
                                        height: "1000px",
                                    }}
                                />
                            </Form.Group>
                        )}
                    </Row>

                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Duration:</Form.Label>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className="d-flex align-items-center"
                                    style={{ width: "50%", marginRight: "10px" }}
                                >
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        max="24"
                                        required
                                        placeholder="Hours"
                                        value={hours}
                                        onChange={(e) => setHours(e.target.value)}
                                        className="new_form_control mr-2"
                                    />
                                    <span>Hours</span>
                                </div>
                                <div
                                    className="d-flex align-items-center mt-2"
                                    style={{ width: "50%" }}
                                >
                                    <Form.Control
                                        type="number"
                                        min="0"
                                        max="59"
                                        required
                                        placeholder="Minutes"
                                        value={minutes}
                                        onChange={(e) => setMinutes(e.target.value)}
                                        className="new_form_control mr-2"
                                    />
                                    <span>Minutes</span>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Documents:</Form.Label>
                            <Form.Control
                                type="file"
                                required
                                multiple
                                accept=".docx, .doc, .pdf, .xls, .xlsx, .csv"
                                id="documentInput"
                                ref={fileInputRef}
                                onChange={handleDocumentChange}
                            />
                        </Form.Group>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {document &&
                                documentNames.map((name, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "5px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                position: "relative",
                                                backgroundColor: "#EBECF2",
                                                borderRadius: "8px",
                                                width: "210px",
                                                padding: "8px",
                                                alignItems: "center",
                                                height: "40px",
                                                marginTop: "30px",
                                            }}
                                        >
                                            {name}
                                        </p>
                                        <CloseIcon
                                            fontSize="small"
                                            color="warning"
                                            onClick={() => handleRemoveDocument(index)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                ))}
                        </div>
                    </Row>
                </Form>
            </div>
            <div className="lecture-form-buttons">
                <Button
                    sx={{
                        background: "#64748B",
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px",
                        marginRight: "0.6rem",
                        marginLeft: "10px",
                    }}
                    onClick={onCancel}
                    className="cancel_button"
                >
                    Cancel
                </Button>
                <Button
                    className="save_lecture_button"
                    sx={{
                        background: "#a4a9fc",
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px",
                        marginRight: "0.6rem",
                        marginLeft: "10px",
                    }}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </div>
        </div>
    );
};

export default LectureForm;
