/** @format */

import React, { useEffect, useState } from "react";
import { Table, Space, message, Modal } from "antd";
import { Button } from "primereact/button";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import moment from "moment/moment";
import {
	DeleteQuestion,
	GetAllForumQuestions,
} from "../../services/Api/forumApi";

const Forum = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 100,
		},
		sortField: null,
		sortOrder: null,
	});

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
			width: "5%",
		},
		{
			title: "Question",
			dataIndex: "question",
			width: "20%",
		},
		{
			title: "Posted By",
			dataIndex: "user_forum_name", // Use the new data index
			width: "20%",
		},
		{
			title: "Answer",
			dataIndex: "answer",
			width: "20%",
			render: (answer) =>
				answer ? (
					<span dangerouslySetInnerHTML={{ __html: answer }} />
				) : (
					<span style={{ color: "red" }}>Answer not provided</span>
				),
		},

		{
			title: "Question Asked On",
			dataIndex: "created_at",
			width: "30%",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "Action",
			dataIndex: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						severity="info"
						style={{ borderRadius: "25px" }}
						onClick={() => navigateToEditForum([record.id])}
					/>
					<Button
						icon="pi pi-trash"
						rounded
						outlined
						severity="danger"
						style={{ borderRadius: "25px", color: "red", borderColor: "red" }}
						onClick={() => handleDelete([record.id])}
					/>
				</Space>
			),
		},
	];

	// Get all users
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllForumQuestions(
				localStorage.getItem("adminToken")
			);

			// Adding index for serial number and ensuring user_forum.name is included
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
				user_forum_name: item.user_forum[0]?.name || "Unknown", // Fallback if user_forum is not available
			}));

			setData(newData);
			setUserBackupData(newData);
		} catch (e) {
			console.log("Error object: ", e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else if (e.response && e.response.status === 500) {
				const errorMessage = e.response.data?.message || e.response.message;
				if (errorMessage === "jwt expired") {
					message.error("Session Expired");
					navigate("/login");
				} else {
					console.log("Error loading data. Please try again later.");
				}
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const LIST = [...userBackupData];
		const searchList = LIST.filter((item) => {
			const questionMatch = item.question
				?.toLowerCase()
				.includes(searchField.toLowerCase());
			const userNameMatch = item.user_forum_name
				?.toLowerCase()
				.includes(searchField.toLowerCase());

			return questionMatch || userNameMatch;
		});

		setData(searchList);
	};

	const handleDelete = (userIds) => {
		Modal.confirm({
			title: "Confirm",
			content: `Are you sure you want to delete ${
				userIds.length > 1 ? "these questions" : "this question"
			}?`,
			onOk: async () => {
				try {
					await DeleteQuestion(userIds, localStorage.getItem("adminToken"));
					message.success("Question(s) deleted successfully");
					getData();
				} catch (error) {
					console.error("Error deleting employee(s):", error);
					message.error("Error deleting employee(s)");
				}
			},
		});
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys);
		},
	};

	const navigateToEditForum = (id) => {
		navigate(`/editForum/${id}`);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">FORUM MANAGEMENT</h3>
					<p className="page-sub-title">View, delete, and add answer</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value);
								}}
								placeholder="Search..."
							/>
						</span>

						<Button
							icon="pi pi-trash"
							severity="danger"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
								cursor: "pointer",
							}}
							onClick={() => handleDelete(selectedRowKeys)}
							disabled={!selectedRowKeys.length}
						/>
					</Box>
				</Box>
			</Box>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
				rowSelection={rowSelection}
			/>
		</Box>
	);
};

export default Forum;
