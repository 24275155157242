/** @format */

import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import { Card } from "primereact/card";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { AddCourse } from "../../services/Api/CourseApi";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { Row, Col } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./Course.scss";

const CreateCourse = () => {
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [authorName, setAuthorName] = useState("");
	const [image, setImage] = useState(null);
	const [imagePreview, setImagePreview] = useState(null);
	const [disable, setDisable] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);

		if (!name) {
			message.error("Please enter Course Name");
			setDisable(false);
			return;
		}
		if (!description) {
			message.error("Please enter description");
			setDisable(false);
			return;
		}
		if (!authorName) {
			message.error("Please enter Author Name");
			setDisable(false);
			return;
		}
		if (!image) {
			message.error("Please insert Image");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("name", name);
			formData.append("description", description);
			formData.append("author_name", authorName);
			formData.append("images", image, `compressed_image.jpg`);

			const response = await AddCourse(formData);

			if (response.status === 201) {
				message.success("Course added successfully!");
				const courseId = response.data.data.id;
				navigate(`/createSection/${courseId}`);
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 400) {
					message.error("This course already exists.");
				} else if (error.response.status === 401) {
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/Login");
					}, 1000);
				} else {
					message.error("Something went wrong");
				}
			} else {
				message.error("Network error");
			}
		} finally {
			setDisable(false);
		}
	};

	const handleImageChange = async (e) => {
		const file = e.target.files[0];

		if (file) {
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

			if (!allowedTypes.includes(file.type)) {
				message.error(
					"Error: Invalid file type. Images (JPEG, JPG, PNG) only!"
				);
				return;
			}

			const compressedFile =
				file.size <= 1024 * 1024
					? file
					: await imageCompression(file, {
							maxSizeMB: 1,
							maxWidthOrHeight: 1920,
					  });

			const compressedImage = new Blob([compressedFile], {
				type: "image/jpeg",
			});

			setImage(compressedImage);

			// Create image preview URL
			const previewUrl = URL.createObjectURL(compressedFile);
			setImagePreview({ name: file.name, previewUrl });
		}
	};

	const handleRemoveImage = () => {
		setImage(null);
		setImagePreview(null);
		document.getElementById("imageInput").value = ""; // Clear the file input
	};

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setDescription(data);
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>
					<h3 className="page-title">Add Course</h3>
					<p className="page-sub-title">Add Course</p>
				</div>
			</Box>
			<Form onSubmit={handleSubmit}>
				<div>
					<div style={{ display: "flex", flexDirection: "row" }}>
						<Card className="left_container">
							<Form.Group className="mb-3">
								<Form.Label>Image:</Form.Label>
								<Form.Control
									type="file"
									required
									accept="image/*"
									id="imageInput"
									onChange={handleImageChange}
								/>
							</Form.Group>

							{imagePreview && (
								<div
									style={{
										border: "1px solid #ccc",
										borderRadius: "5px",
										padding: "10px",
										marginBottom: "10px",
									}}
								>
									<div style={{ display: "flex", alignItems: "center" }}>
										<img
											src={imagePreview.previewUrl}
											alt="Preview"
											style={{
												height: "80px",
												width: "80px",
												marginRight: "10px",
											}}
										/>
										<span>{imagePreview.name}</span>
										<DeleteIcon
											fontSize="large"
											color="warning"
											style={{ marginLeft: "auto", cursor: "pointer" }}
											onClick={handleRemoveImage}
										/>
									</div>
								</div>
							)}
						</Card>
						<Card className="right_container">
							<div>
								<Row>
									<Col>
										<Form.Group className="mb-3">
											<Form.Label>Course Name:</Form.Label>
											<Form.Control
												type="text"
												required
												placeholder="Please enter your Course Name!"
												value={name}
												onChange={(e) => setName(e.target.value)}
												className="new_form_control"
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="mb-3">
											<Form.Label>Author Name:</Form.Label>
											<Form.Control
												type="text"
												required
												placeholder="Please enter Author Name!"
												value={authorName}
												onChange={(e) => setAuthorName(e.target.value)}
												className="new_form_control"
											/>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="mb-3">
									<Form.Label>Product Description:</Form.Label>

									<CKEditor
										editor={ClassicEditor}
										onChange={handleEditorChange}
										config={{
											height: "1000px",
										}}
									/>
								</Form.Group>
							</div>
						</Card>
					</div>
					<div className="buttons_divison">
						<Button
							className="save_button"
							sx={{
								background: "#a4a9fc",
								fontSize: "14px",
								fontWeight: "bold",
								padding: "10px",
								marginRight: "0.6rem",
								marginLeft: "10px",
							}}
							onClick={handleSubmit}
							disabled={disable}
						>
							{disable ? "Saving...." : "Save & Next"}
						</Button>
					</div>
				</div>
			</Form>
		</Box>
	);
};

export default CreateCourse;
