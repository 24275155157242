import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Button } from "primereact/button";
import "./ChangePassword.css";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChangeAdminPassword } from "../../../../services/Api/Api";
import { toast } from "react-toastify";
import { Card } from "primereact/card";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { message } from "antd";

const ChangePassword = () => {
	const navigate = useNavigate();
	const navigateToDashboard = () => {
		navigate("/");
	};

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showOldPassword, setShowOldPassword] = useState(false); // Separate state for old password visibility
	const [showNewPassword, setShowNewPassword] = useState(false); // Separate state for new password visibility
	const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Separate state for confirm password visibility

	const handleToggleOldPassword = () => {
		setShowOldPassword(!showOldPassword);
	};

	const handleToggleNewPassword = () => {
		setShowNewPassword(!showNewPassword);
	};

	const handleToggleConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const handleChangePassword = async (e) => {
		e.preventDefault();

		if (
			oldPassword?.length === 0 ||
			newPassword?.length === 0 ||
			confirmPassword?.length === 0
		) {
			message.error("Please enter valid input", {
				position: "top-right",
				autoClose: 500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return;
		}

		if (newPassword !== confirmPassword) {
			message.error("New Password and confirm password should be equal", {
				position: "top-right",
				autoClose: 500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return;
		}

		const formData = new FormData();
		formData.append("old_password", oldPassword);
		formData.append("new_password", newPassword);
		formData.append("confirm_password", confirmPassword);

		try {
			const res = await ChangeAdminPassword(formData);
			if (res?.status == 200) {
				message.success(" Password changed !", {
					position: "top-right",
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			} else {
				message.error(res?.data?.message, {
					position: "top-right",
					autoClose: 500,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
		} catch (error) {
			message.error(error?.response?.data?.message, {
				position: "top-right",
				autoClose: 500,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	};

	const navigateToAdmin = () => {
		navigate("/");
	};

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
				<h3>CHANGE PASSWORD</h3>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={(e) => {
							navigateToAdmin();
						}}
						style={{ borderRadius: "5px", height: "40px" }}
					>
						<span style={{ marginLeft: "5px" }}> Return to Dashboard</span>
					</Button>
				</div>
			</Box>
			<Card style={{ padding: "20px" }}>
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>Current Password</Form.Label>
						<div
							className="current-password-toggle-icon"
							onClick={handleToggleOldPassword}
						>
							{showOldPassword ? <FaEye /> : <FaEyeSlash />}
						</div>
						<Form.Control
							type={showOldPassword ? "text" : "password"}
							placeholder="Current Password"
							onChange={(event) => {
								setOldPassword(event.target.value);
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-3" style={{ position: "relative" }}>
						<Form.Label>New Password</Form.Label>
						<div
							className="new-password-toggle-icon"
							onClick={handleToggleNewPassword}
						>
							{showNewPassword ? <FaEye /> : <FaEyeSlash />}
						</div>
						<Form.Control
							type={showNewPassword ? "text" : "password"}
							placeholder="Enter New Password"
							onChange={(event) => {
								setNewPassword(event.target.value);
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-4" style={{ position: "relative" }}>
						<Form.Label>Confirm Password</Form.Label>
						<div
							className="confirm-password-toggle-icon"
							onClick={handleToggleConfirmPassword}
						>
							{showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
						</div>
						<Form.Control
							type={showConfirmPassword ? "text" : "password"}
							placeholder="Enter Confirm Password"
							onChange={(event) => {
								setConfirmPassword(event.target.value);
							}}
						/>
					</Form.Group>

					<Button
						icon="pi pi-check"
						severity="success"
						type="submit"
						onClick={handleChangePassword}
						style={{
							borderRadius: "10px",
							marginLeft: "10px",
							marginTop: "10px",
						}}
					>
						Save
					</Button>
					<Button
						severity="secondary"
						onClick={(e) => {
							navigateToDashboard();
						}}
						style={{ borderRadius: "10px", marginLeft: "10px" }}
					>
						Cancel
					</Button>
				</Form>
			</Card>
		</Box>
	);
};

export default ChangePassword;
