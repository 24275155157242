/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";


export const GetAllForumQuestions = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + "admin/getAllQuestions", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetForumById = async (id) => {
	return await axios.get(BASE_URL + "admin/getQuestionById?id="+ id);
};

export const UpdateForum = async ( formData) => {
	return await axios.post(BASE_URL + `admin/updateAnswer`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteQuestion = async (user_id) => {
	return axios.post(
		BASE_URL + "admin/deleteQuestion",
		{
			user_id: user_id,
		}
	);
};
