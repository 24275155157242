/** @format */

import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import "./Course.scss";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { message } from "antd";
import { AddSection } from "../../services/Api/CourseApi";
import { FaPlus } from "react-icons/fa6";

const CreateSection = () => {
	const { id: courseId } = useParams(); // Using useParams to get courseId
	console.log(courseId, "courseId");
	const [name, setName] = useState("");
	const [disable, setDisable] = useState(false);
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleSubmit = async (event) => {
		event.preventDefault();
		setDisable(true);
		if (!name) {
			message.error("Please enter Section Name");
			setDisable(false);
			return;
		}

		try {
			const formData = new FormData();
			formData.append("course_id", courseId);
			formData.append("name", name);

			const response = await AddSection(formData);

			if (response.status === 201) {
				message.success("Section added successfully!");
			}
			navigate(`/createLecture/${courseId}`);
			setDisable(false);
		} catch (error) {
			if (error.response.status === 401) {
				localStorage.removeItem("adminToken");
				setTimeout(() => {
					navigate("/Login");
				}, 1000);
			} else {
				message.error("Something went wrong");
			}
			setDisable(false);
		}
	};

	return (
		<Box m="20px">
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>
					<h3 className="page-title">Add Section</h3>{" "}
					<p className="page-sub-title">Add Section</p>
				</div>
			</Box>
			<div className="main_div_section">
				<h6
					style={{
						marginTop: "20px",
						fontSize: "25px",
						fontWeight: "bold",
						color: "grey",
					}}
				>
					No Section Added
				</h6>

				<Button
					startIcon={<FaPlus />}
					className="add_section_button"
					sx={{
						background: "#a4a9fc",
						fontSize: "14px",
						fontWeight: "bold",
						padding: "10px",
						marginRight: "0.6rem",
						marginLeft: "10px",
					}}
					onClick={handleShow}
				>
					Add New Section
				</Button>

				<Modal
					show={show}
					onHide={handleClose}
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>Add Section</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group className="mb-3">
								<Form.Label>Section Name:</Form.Label>
								<Form.Control
									type="text"
									required
									placeholder="Please enter your Section Name!"
									value={name}
									onChange={(e) => setName(e.target.value)}
									className="new_form_control"
								/>
							</Form.Group>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={handleClose}
							sx={{
								width: "100px",
								color: "white",
								background: "#7F7F7F",
								fontSize: "14px",
								fontWeight: "bold",
								padding: "10px",
								marginRight: "0.6rem",
								marginLeft: "10px",
							}}
						>
							Close
						</Button>
						<Button
							className="save_section_button"
							sx={{
								background: "#a4a9fc",
								fontSize: "14px",
								fontWeight: "bold",
								padding: "10px",
								marginRight: "0.6rem",
								marginLeft: "10px",
							}}
							onClick={handleSubmit}
							disabled={disable}
						>
							{disable ? "Saving...." : "Save"}
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</Box>
	);
};

export default CreateSection;
