/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table, Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import { GetAllUserCertificate } from "../../services/Api/CourseApi";


function Certificate() {
	const [pageSize, setPageSize] = useState(50);
	const [adminData, setAdminData] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// Fetch all user certificates data
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllUserCertificate(
				localStorage.getItem("adminToken")
			);
			setAdminData(result.data.data);
		} catch (e) {
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				message.error("You do not have access to this page.");
			} else {
				message.error("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	// Define the columns for the table
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 90,
		},
		{
			title: "Course Name",
			dataIndex: ["watchList_course", "name"],
			key: "course_name",
			width: 150,
			render: (text, record) => {
				return record.watchList_course && record.watchList_course.name
					? record.watchList_course.name
					: "No Course";
			},
		},
		{
			title: "User Name",
			dataIndex: "user_watchlist",
			key: "user_name",
			width: 350,
			render: (text, record) => {
				return record.user_watchlist.length > 0
					? record.user_watchlist[0].name
					: "No User";
			},
		},
		{
			title: "Progress Of Course",
			dataIndex: "course_progress",
			key: "course_progress",
			width: 350,
		},
	];

	return (
		<Box >
			{loading ? (
				<Spin size="large" />
			) : (
				<>
					<Box display="flex" justifyContent="space-between" alignItems="center">
                    <div>
					<h3 className="page-title">Certificate MANAGEMENT</h3>
					<p className="page-sub-title">View All Certificate</p>
				</div>
					</Box>
					<div
						style={{
							width: "100%",
							marginTop: "20px",
						}}
					>
						<Table
							dataSource={adminData}
							columns={columns}
							rowKey="id"
							pagination={{
								pageSize: pageSize,
								showSizeChanger: true,
								pageSizeOptions: [50, 100, 150],
								onShowSizeChange: (current, size) => setPageSize(size),
							}}
							scroll={{ y: 550 }}
						/>
					</div>
				</>
			)}
		</Box>
	);
}

export default Certificate;
