/** @format */

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material"; // MUI Box for layout
import { Table, Tooltip, message, Spin, Space } from "antd"; // Ant Design imports
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { GetJourneyStarted } from "../../../services/Api/ContentApi";

const JourneyStarted = () => {
	const navigate = useNavigate();
	const [roleData, setRoleData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 100,
		},
		sortField: null,
		sortOrder: null,
	});
	// Fetch data from API
	const getData = async () => {
		try {
			setLoading(true);
			const result = await GetJourneyStarted(
				localStorage.getItem("adminToken")
			);
			setRoleData(result.data.data);
		} catch (e) {
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				message.error("You do not have access to this page as a sub-admin.");
			} else {
				message.error("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	// Function to navigate to edit page
	const navigateToEditCalculator = (id) => {
		navigate(`/editJourneyStarted/${id}`);
	};

	// Define table columns for Ant Design table
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 150,
		},
		{
			title: "Heading",
			dataIndex: "name",
			key: "name",
			width: 350,
		},
		{
			title: "Actions",
			key: "actions",
			width: 150,
			render: (text, record) => (
				<Space size="middle">
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						severity="info"
						style={{ borderRadius: "25px" }}
						onClick={() => navigateToEditCalculator([record.id])}
					/>
				</Space>
			),
		},
	];

	return (
		<Box>
			<div>
				<h3 className="page-title">Journey Started Content</h3>
				<p className="page-sub-title">View, Edit & Delete</p>
			</div>
			<Box>
				{loading ? (
					<Spin size="large" />
				) : (
					<Table
						dataSource={roleData}
						columns={columns}
						rowKey={(record) => record.id}
						pagination={tableParams.pagination}
						loading={loading}
						onChange={handleTableChange}
					/>
				)}
			</Box>
		</Box>
	);
};

export default JourneyStarted;
