/** @format */

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Table,  Spin, Space } from "antd"; // Ant Design imports
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { GetLetsGetSatrted } from "../../../services/Api/ContentApi";


const LetsGetStarted = () => {
	const navigate = useNavigate();
	const [roleData, setRoleData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 100,
		},
		sortField: null,
		sortOrder: null,
	});

	// Fetch data
	const getData = async () => {
		try {
			setLoading(true);
			const result = await GetLetsGetSatrted(
				localStorage.getItem("adminToken")
			);
			setRoleData(result.data.data);
			console.log("admins", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	// Navigate to edit calculator page
	const navigateToEditCalculator = (id) => {
		navigate(`/editLetsGetStared/${id}`);
	};

	// Define columns for the Ant Design table
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 150,
		},
		{
			title: "Heading",
			dataIndex: "name",
			key: "name",
			width: 350,
		},
		{
			title: "Actions",
			key: "actions",
			width: 150,
			render: (text, record) => (
				<div style={{ display: "flex", alignItems: "center" }}>
					<Space size="middle">
						<Button
							icon="pi pi-pencil"
							rounded
							outlined
							severity="info"
							style={{ borderRadius: "25px" }}
							onClick={() => navigateToEditCalculator([record.id])}
						/>
					</Space>
				</div>
			),
		},
	];

	return (
		<Box m="20px">
			<div>
				<h3 className="page-title">Let's Get Started</h3>
				<p className="page-sub-title">View, Edit & Delete</p>
			</div>

			<Box mt="20px">
				{loading ? (
					<Spin size="large" />
				) : (
					<Table
						dataSource={roleData}
						columns={columns}
						rowKey={(record) => record.id}
						pagination={tableParams.pagination}
						loading={loading}
						onChange={handleTableChange}
					/>
				)}
			</Box>
		</Box>
	);
};

export default LetsGetStarted;
