/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

//get all user
export const GetUsers = async (adminToken) => {
	try {
		const res = await axios.get(BASE_URL + `admin/getAllUsers`, {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

//get userby id
export const GetUserById = async (id) => {
	return await axios.get(BASE_URL + `admin/users/getUserById/${id}`);
};

//admin add user
export const AddUser = async ({ email_id, name }) => {
	const formData = new FormData();

	formData.append("email_id", email_id);
	formData.append("name", name);

	return await axios.post(BASE_URL + "admin/addUser", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

//delete user
export const DeleteUser = async (user_id) => {
	return axios.post(
		BASE_URL + "admin/deleteUser",
		{
			user_id: user_id,
		},
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};
