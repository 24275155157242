/** @format */

import React, { useState, useRef, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { LuSettings } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { SiCoursera } from "react-icons/si";
import { CiLogin } from "react-icons/ci";
import { Layout, theme, Menu } from "antd";
import { IoLayers } from "react-icons/io5";
import { MdContacts } from "react-icons/md";
import { MdForum } from "react-icons/md";
import { LuLayers } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { MdManageAccounts } from "react-icons/md";
import { message } from "antd";
import Login from "../pages/Login/Login";
import { GetAdminProfile } from "../services/Api/Api";
import { PiCertificateBold } from "react-icons/pi";
import logo from "../assets/Logo.243c74147ca2a2329d67.png";
import "./MainLayout.scss";
const { Header, Sider, Content } = Layout;

const MainLayout = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [idData, setIdData] = React.useState({});
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const getData = async () => {
		try {
			// Get the token from localStorage
			const token = localStorage.getItem("adminToken");

			// Call the API to get admin profile
			let result = await GetAdminProfile(token);
			console.log(result, "result");

			// Check if the response contains an error status
			if (result.status === 500 || result.status === 403) {
				const errorMessage = result.data?.message || result.message;

				// Handle invalid signature or session expiration (500 status)
				if (result.status === 500 && errorMessage === "invalid signature") {
					message.error("Session Expired");
					localStorage.removeItem("adminToken"); // Clear the token
					navigate("/login"); // Redirect to login
					return; // Stop further execution
				}

				// Handle 403 Forbidden error (Admin not found or inactive)
				if (
					result.status === 403 &&
					errorMessage === "Admin not found or inactive"
				) {
					message.error("You have been removed by your admin");
					localStorage.removeItem("adminToken"); // Clear the token
					navigate("/login"); // Redirect to login
					return; // Stop further execution
				}
			}

			// If no error, update the state with the data
			setIdData(result.data.data);
		} catch (e) {
			// Log the full error object for debugging
			console.log("Error: ", e);

			// Check for network or server errors
			if (e.response) {
				const status = e.response.status;
				const errorMessage = e.response.data?.message || e.response.message;

				// Handle invalid signature or session expiration (500 status)
				if (status === 500 && errorMessage === "invalid signature") {
					message.error("Session Expired");
					localStorage.removeItem("adminToken"); // Clear the token
					navigate("/login"); // Redirect to login
					return; // Stop further execution
				}

				// Handle 403 Forbidden error (Admin not found or inactive)
				if (status === 403 && errorMessage === "Admin not found or inactive") {
					message.error("You have been removed by your admin");
					localStorage.removeItem("adminToken"); // Clear the token
					navigate("/login"); // Redirect to login
					return; // Stop further execution
				}
			} else {
				// Handle other cases like network issues, unexpected errors
				console.log("Error loading data. Please try again later.");
			}
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			getData();
		}, 10000);
		return () => clearInterval(intervalId);
	}, []);

	const [collapsed, setCollapsed] = useState(false);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const navigate = useNavigate();

	function logout() {
		localStorage.removeItem("adminToken");
		message.success(" Logged Out Successfully");
		setTimeout(() => {
			navigate("/login");
		}, 1000);
	}

	const token = localStorage.getItem("adminToken");

	return (
		<>
			{token ? (
				<Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						className="sidebar"
					>
						<div className="logo">
							<h2 className="text-white text-center py-3 mb-0 custom-logo-size">
								<span className="sm-logo">TLE</span>
								<span className="lg-logo" style={{ color: "black" }}>
									<img
										src={logo}
										alt="Logo"
										style={{
											width: "120px",
											objectFit: "contain",
										}}
									/>
								</span>
							</h2>
						</div>

						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={[""]}
							onClick={({ key }) => {
								if (key === "signout") {
								} else {
									navigate(key);
								}
							}}
							items={[
								{
									key: "/",
									icon: <AiOutlineDashboard className="fs-4" />,
									label: "Dashboard",
								},
								{
									key: "/users",
									icon: <FaUsers className="fs-4" />,
									label: "Users",
								},
								{
									key: "/course-list",
									icon: <SiCoursera className="fs-4" />,
									label: "Course",
								},
								{
									key: "/certificate",
									icon: <PiCertificateBold className="fs-4" />,
									label: "Certificate",
								},

								{
									key: "/contactUs",
									icon: <MdContacts className="fs-4" />,
									label: "Contact Us",
								},
								{
									key: "/forum",
									icon: <MdForum className="fs-4" />,
									label: "Forum",
								},
								{
									key: "aboutUs",
									icon: <IoLayers className="fs-4" />,
									label: "Content",
									children: [
										{
											key: "aboutUs",
											icon: <LuLayers className="fs-4" />,
											label: "About Us",
										},
										{
											key: "/calculator",
											icon: <LuLayers className="fs-4" />,
											label: "Calculator Content",
										},
										{
											key: "/letsGetStarted",
											icon: <LuLayers className="fs-4" />,
											label: "Let's Get Started",
										},
										{
											key: "/missionVision",
											icon: <LuLayers className="fs-4" />,
											label: "Mission Vision",
										},
										{
											key: "/journey",
											icon: <LuLayers className="fs-4" />,
											label: "Journey Content",
										},
									],
								},
								{
									key: "/adminList",
									icon: <MdManageAccounts className="fs-4" />,
									label: "Access Management",
								},
							]}
						/>
					</Sider>
					<Layout className="site-layout">
						<Header
							className="d-flex justify-content-between ps-1 pe-5"
							style={{
								padding: 0,
								background: colorBgContainer,
							}}
						>
							{React.createElement(
								collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
								{
									className: "trigger",
									onClick: () => setCollapsed(!collapsed),
								}
							)}
							<div className="d-flex gap-4 align-items-center">
								{/* <div className="position-relative clicked">
									<FiMail className="fs-3" onClick={handleMailClick} />
								</div> */}

								<div className="vertical-line"></div>
								<div className="position-relative">
									<div className="d-flex align-items-center">
										<div
											role="button"
											id="dropdownMenuLink"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											className="d-flex align-items-center"
										>
											<FaRegCircleUser className="fs-3 me-2" />
											<div className="d-flex flex-column">
												<p className="mb-0" style={{ fontWeight: "700" }}>
													{idData?.name}
												</p>
												<p className="mb-0">
													{idData?.admin_roles &&
													Array.isArray(idData.admin_roles) &&
													idData.admin_roles.length > 0
														? idData.admin_roles[0]?.name
														: ""}
												</p>
											</div>
										</div>
										<div
											className="dropdown-menu admin"
											aria-labelledby="dropdownMenuLink"
											style={{
												// margin: "10px !important",
												borderTopColor: "purple",
												borderTopWidth: "4px",
											}}
										>
											<li to="/viewAdmin">
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/viewAdmin"
												>
													<FiUser style={{ marginRight: "10px" }} />
													View Profile
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/reset-password"
												>
													<LuSettings style={{ marginRight: "10px" }} />
													Change Password
												</Link>
											</li>

											<div className="dropdown-divider"></div>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													onClick={() => logout()}
												>
													<CiLogin style={{ marginRight: "10px" }} />
													Sign Out
												</Link>
											</li>
										</div>
									</div>
								</div>
							</div>
						</Header>
						<Content
							style={{
								margin: "24px 16px",
								padding: 24,
								minHeight: 280,
								background: colorBgContainer,
							}}
						>
							<ToastContainer
								position="top-right"
								autoClose={250}
								hideProgressBar={false}
								newestOnTop={true}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								theme="light"
							/>
							<Outlet />
						</Content>
					</Layout>
				</Layout>
			) : (
				<Login />
			)}
		</>
	);
};
export default MainLayout;
