/** @format */

import React, { useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Card } from "antd";
import { message } from "antd";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import { Button } from "primereact/button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GetForumById, UpdateForum } from "../../services/Api/forumApi";

const EditForum = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [serviceData, setServiceData] = useState({
		question: "",
		answer: "",
	});

	useLayoutEffect(() => {
		GetForumById(id)
			.then((res) => {
				setServiceData(res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			});
	}, [id]);

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setServiceData((prevData) => ({
			...prevData,
			answer: data,
		}));
	};

	const onChange = (e) => {
		setServiceData({ ...serviceData, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
        formData.append("question_id", id);
		formData.append("answer", serviceData?.answer ? serviceData?.answer : "");
		UpdateForum(formData)
			.then((res) => {
				if (res.status === 200) {
					message.success("Answer edited successfully!");
				}
				navigate("/forum");
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					message.error("Token expired!");
					localStorage.removeItem("adminToken");
					setTimeout(() => {
						navigate("/login");
					}, 3000);
				} else {
					message.error("Something went wrong");
				}
			});
	};

	const navigateToForum = () => {
		navigate("/forum");
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">Update FORUM</h3>
					<p className="page-sub-title">Provide or edit asnswr</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToForum}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Forum</span>
					</Button>
				</div>
			</Box>
			<Card style={{ padding: "20px" }}>
				<Form>
					<Row>
						<Form.Group className="mb-3">
							<Form.Label>Question:</Form.Label>
							<Form.Control
								name="name"
                                disabled
								defaultValue={serviceData?.question}
								type="text"
								onChange={(e) => onChange(e)}
							/>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group className="mb-3">
							<Form.Label>Answer::</Form.Label>
							<CKEditor
								editor={ClassicEditor}
								onChange={handleEditorChange}
								data={serviceData.answer}
								config={{
									height: "50%",
								}}
							/>
						</Form.Group>
					</Row>

					<div style={{ marginTop: "50px" }}>
						<Button
							icon="pi pi-check"
							severity="info"
							type="submit"
							onClick={(e) => {
								handleSubmit(e);
							}}
							style={{
								height: "45px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							Submit
						</Button>
						<Button
							icon="pi pi-times"
							severity="secondary"
							onClick={() => navigateToForum()}
							style={{
								marginLeft: "10px",
								marginTop: "10px",
								height: "45px",
								padding: "20px",
								borderRadius: "5px",
							}}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Card>
		</Box>
	);
};

export default EditForum;
