/** @format */

import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import ChangePassword from "./pages/Sidebar/Settings/ChangePassword/ChangePassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import AdminList from "./pages/AdminList";
import User from "./pages/Customer/User";
import AddUser from "./pages/Customer/AddUser";
import ViewCustomer from "./pages/Customer/ViewCustomer";
import AdminUser from "./pages/AdminManagement/AdminUser";
import ViewAdmin from "./pages/AdminManagement/ViewAdmin";
import AddAdmin from "./pages/AdminManagement/AddAdmin";
import EditAdmin from "./pages/AdminManagement/EditAdmin";
import LoginLogs from "./pages/Sidebar/LoginActivity/LoginLogs";
import Settings from "./pages/Sidebar/Settings/Settings";
import Service from "./pages/Services/Service";
import AddService from "./pages/Services/AddService";
import EditService from "./pages/Services/EditService";
import ViewService from "./pages/Services/ViewService";
import AboutUs from "./pages/Content/AboutUs/AboutUs";
import EditAboutUs from "./pages/Content/AboutUs/EditAboutUs";
import Test from "./pages/test";
import Error401 from "./pages/ErrorPages/Error401";
import ContactUs from "./pages/ContactUs/ContactUs";
import Certificate from "./pages/Certificate/Certificate";
import Calculator from "./pages/Content/Calculator/Calculator";
import EditCalculator from "./pages/Content/Calculator/EditCalculator";
import LetsGetStarted from "./pages/Content/LetsGetStarted/LetsGetStarted";
import EditLetsGetStared from "./pages/Content/LetsGetStarted/EditLetsGetStarted";
import MissionVision from "./pages/Content/MissionVision/MissionVision";
import EditMissionVision from "./pages/Content/MissionVision/EditMissionVision";
import JourneyStarted from "./pages/Content/JourneyStarted/JourneyStarted"; 
import EditJourneyStarted from "./pages/Content/JourneyStarted/EditJourneyStarted";
import Forum from "./pages/Forum/Forum";
import EditForum from "./pages/Forum/EditForum";
import Course from "./pages/Course/Course";
import CreateCourse from "./pages/Course/CreateCourse";
import CreateLecture from "./pages/Course/CreateLecture";
import CreateSection from "./pages/Course/CreateSection";
import LectureForm from "./pages/Course/LectureForm";
import EditLectureForm from "./pages/Course/EditLectureForm";
import UpdateCourse from "./pages/Course/Edit/UpdateCourse";
import UpdateSection from "./pages/Course/Edit/UpdateSection";
import QuizPage from "./pages/Course/Quiz/Quiz";
import ViewQuestions from "./pages/Course/Quiz/ViewQuestions";
import ViewCourses from "./pages/Course/Course";



function App() {
	return (
		<Router>
			<Routes>
				<Route path="/forgot-password" element={<Forgotpassword />} />
				<Route path="/login" element={<Login />} />
				<Route path="/error401" element={<Error401 />} />
				<Route path="/" element={<MainLayout />}>
					<Route index element={<Dashboard />} />
					<Route path="/reset-password" element={<ChangePassword />} />
					<Route path="/users" element={<User />} />
					<Route path="/addUser" element={<AddUser />} />
					<Route path="/viewUser/:id" element={<ViewCustomer />} />
					<Route path="/test" element={<Test />} />
					<Route path="adminUser" element={<AdminList />} />
					<Route path="/adminList" element={<AdminUser />} />
					<Route path="/viewAdmin" element={<ViewAdmin />} />
					<Route path="/addAdmin" element={<AddAdmin />} />
					<Route path="/editAdmin/:id" element={<EditAdmin />} />
					<Route path="/loginLogs" element={<LoginLogs />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/services" element={<Service />} />
					<Route path="/addService" element={<AddService />} />
					<Route path="/editService/:id" element={<EditService />} />
					<Route path="/viewService/:id" element={<ViewService />} />			
					<Route path="/aboutUs" element={<AboutUs />} />
					<Route path="/editAboutUs/:id" element={<EditAboutUs />} />
					<Route path="/calculator" element={<Calculator />} />
					<Route path="/editCalculator/:id" element={<EditCalculator />} />
					<Route path="/letsGetStarted" element={<LetsGetStarted />} />
					<Route path="/editLetsGetStared/:id" element={<EditLetsGetStared />} />
					<Route path="/missionVision" element={<MissionVision />} />
					<Route path="/editMissionVision/:id" element={<EditMissionVision />} />
					<Route path="/journey" element={<JourneyStarted />} />
					<Route path="/editJourneyStarted/:id" element={<EditJourneyStarted />} />
					<Route path="/contactUs" element={<ContactUs />} />
					<Route path="/forum" element={<Forum />} />
					<Route path="/editForum/:id" element={<EditForum />} />
                     {/* course  */}
					<Route path="/course-list" element={<Course />} />
					<Route path="/createCourse" element={<CreateCourse />} />
					<Route path="/certificate" element={<Certificate />} />
					<Route path="/createLecture/:id" element={<CreateLecture />} />
					<Route path="/viewCourse/:id" element={<ViewCourses />} />
					<Route path="/createSection/:id" element={<CreateSection />} />
					<Route path="/editForum/:id" element={<LectureForm />} />
					<Route path="/editForum/:id" element={<EditLectureForm />} />
					<Route path="/editCourse/:id" element={<UpdateCourse />} />
					<Route path="/editSection/:id" element={<UpdateSection />} />
					<Route path="/editForum/:id" element={<QuizPage />} />
					<Route path="/viewQuestion/:id" element={<ViewQuestions />} />
					
				</Route>
			</Routes>
		</Router>
	);
}

export default App;
