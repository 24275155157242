/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

//admin login
export const AdminLogin = async (formdata) => {
	console.log("result", formdata);
	return await axios.post(BASE_URL + "admin/login", formdata);
};


// ********************************ADMIN API***********************************************************
export const ChangeAdminPassword = async (data) => {
	return axios.post(BASE_URL + "admin/changePassword", data, {
		headers: {
			"Content-Type": "multipart/form-data",
			"x-access-token": `${localStorage.getItem("adminToken")}`,
		},
	});
};

export const GetAdmins = async (adminToken, role_id) => {
	try {
		const res = await axios.get(BASE_URL + "admin/getAllAdmin", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		// Handle error if needed
		console.error("Error fetching users:", error);
		throw error;
	}
};

export const GetAdminById = async (id) => {
	return await axios.get(BASE_URL + "admin/getAdminById?id=" + id);
};

export const UpdateAdmin = async (formData) => {
	return await axios.put(BASE_URL + "admin/updateAdmin", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};

export const DeleteAdmin = async (id,adminToken) => {
	
	return axios.delete(BASE_URL + "admin/deleteAdmin?id="+ id, {

		headers: {
			"Content-Type": "multipart/form-data",
			"x-access-token": adminToken,
		},
	});
};

export const GetAdminProfile = async (adminToken) => {
	return axios.get(BASE_URL + "admin/getAdminProfile", {
		headers: {
			"Content-Type": "multipart/form-data",
			"x-access-token": adminToken,
		},
	});
};

export const CreateAdmin = async (formData) => {
	return await axios.post(BASE_URL + "admin/register", formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};





// get contact us

export const GetAllContactUs = async (adminToken, role_id) => {
	try {
	  const res = await axios.get(BASE_URL + "user/getAllContactForm", {
		headers: {
		  "x-access-token": `${adminToken}`,
		  "Content-Type": "multipart/form-data",
		},
	  });
  
	  return res;
	} catch (error) {
	  console.error("Error fetching users:", error);
	  throw error;
	}
  };