/** @format */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table, Tooltip, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { GetAdmins, DeleteAdmin } from "../../services/Api/Api";
import Alert from "../Customer/Alert";

const AdminUser = () => {
	const navigate = useNavigate();
	const [roleData, setRoleData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 100,
		},
		sortField: null,
		sortOrder: null,
	});

	// Fetch all admins
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAdmins(localStorage.getItem("adminToken"));
			setRoleData(result.data.data);
		} catch (e) {
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				message.error("You do not have access to this page as a sub-admin.");
			} else {
				message.error("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	// Navigate to Add Admin page
	const navigateToAddAdmin = () => {
		navigate("/addAdmin");
	};

	// Navigate to Edit Admin page
	const navigateToEditAdmin = (id) => {
		navigate(`/editAdmin/${id}`);
	};

	// Remove Admin function
	const removeUser = (id) => {
		DeleteAdmin(id, localStorage.getItem("adminToken"))
			.then((res) => {
				message.success(res?.data?.message);
				getData(); // Refresh data after deletion
			})
			.catch((error) => {
				message.error("Error deleting admin.");
			});
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	// Table columns
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 150,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 350,
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			width: 350,
		},
		{
			title: "Role",
			dataIndex: "admin_roles",
			key: "role",
			width: 350,
			render: (admin_roles) => admin_roles[0]?.name || "",
		},
		{
			title: "Actions",
			key: "action",
			width: 200,
			render: (text, record) => (
				<div>
					{/* Edit Admin */}
					<Tooltip title="Edit">
						<Button
							icon="pi pi-pencil"
							rounded
							outlined
							severity="info"
							style={{ borderRadius: "25px",marginRight:"10px" }}
							onClick={() => navigateToEditAdmin([record.id])}
						/>
					</Tooltip>

					{/* Delete Admin */}
					<Alert title="Admin" handleDelete={() => removeUser(record.id)} />
				</div>
			),
		},
	];

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">Admin List</h3>
					<p className="page-sub-title">View, Edit, Delete and Create Admin</p>
				</div>
				<Button
					severity="info"
					label="Add New Admin"
					icon="pi pi-plus"
					onClick={navigateToAddAdmin}
					style={{ marginBottom: "20px" }}
				></Button>
			</Box>

			<Table
				dataSource={roleData}
				columns={columns}
				rowKey={(record) => record.id}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default AdminUser;
